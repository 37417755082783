.whatsapp-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed; /* Fixed position to stay in view */
    bottom: 70px;
    right: 16px;
    background-color: #25D366; /* WhatsApp green */
    color: white;
    padding: 12px;
    border-radius: 50%;
    font-size: 1.5em;
    text-decoration: none;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
    animation: pulse 1.5s infinite; /* Apply the pulse animation */
}

.whatsapp-icon:hover {
    background-color: #128C7E;
}

/* Keyframes for the pulse animation */
@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
