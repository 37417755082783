/* src/components/Header.css */
.icon {
    background-color: #f8f9fa; /* Customize this color */
    border-radius: 50%;
    padding: 10px;
  }
  
  .bg-fifth {
    background-color: #5d50c6; /* Customize this color */
  }
  
  .bg-secondary {
    background-color: #dee2e6; /* Customize this color */
  }
  
  .bg-tertiary {
    background-color: #8cc152; /* Customize this color */
  }
  
  .text {
    font-size: 16px; /* Adjust font size as needed */
  }
  
  .icon svg {
    font-size: 14px; /* Adjust icon size as needed */
    color: #fff; /* Adjust icon color as needed */
  }
  

  /* AboutSection.css */
/* src/components/AboutSection.css */
.ftco-section {
    padding: 10px 0;
  }
  
  .ftco-no-pt {
    padding-top: 0;
  }
  
  .ftco-no-pb {
    padding-bottom: 0;
  }
  
  .btn-tertiary {
    background-color: #007bff; /* Adjust as needed */
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
  }
  
  .checklist {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .checklist li {
    margin-bottom: 10px;
  }
  
  .toggle-div {
    display: none;
  }
  
  .toggle-div.expanded {
    display: block;
  }
  .btn-tertiary {
    background-color: #007bff; /* Example background color */
    color: white;
  }

  /* IntroSection.css */
.ftco-intro {
    position: relative;
    color: white;
    padding: 60px 0;
  }
  
  .ftco-intro .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
  
  .ftco-intro .container {
    position: relative;
    z-index: 2;
  }
  
  .ftco-intro h2 {
    font-size: 2rem;
    margin-bottom: 20px;
    color:#1941e2 !important;
  }
  
  .ftco-intro .text-black {
    color: #000;
  }
  
  .ftco-intro .bg-tertiary {
    background-color: #8cc152; /* Example color */
  }
  
  .ftco-intro .bg-primary {
    background-color: #007bff; /* Example color */
  }
  
  .ftco-intro .bg-secondary {
    background-color: #6c757d; /* Example color */
  }
  
  .ftco-intro .btn-quarternary {
    background-color: #343a40; /* Example color */
    color: #fff;
  }
  
  .btn-quarternary {
    border: 1px solid transparent;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .btn-quarternary:hover {
    background-color: #495057; /* Darker shade for hover effect */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      visibility: hidden;
    }
    to {
      opacity: 1;
      visibility: visible;
    }
  }
  
  /* PhotoGallery.css */

/* PhotoGallery.css */

/* Gallery container styling */
.ftco-gallery {
    padding: 4rem 0;
  }
  
  .ftco-gallery .carousel {
    text-align: center;
    margin-bottom: 1rem;
    height:auto !important;
  }
  
  .large-image {
    max-width: 50%;
    max-height: auto;
    border: 2px solid #ddd;
  }
  
  .thumbnails {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
  }
  
  .thumbnail {
    width: 80px;
    height: 60px;
    cursor: pointer;
    border: 1px solid #ddd;
    transition: opacity 0.3s ease;
  }
  
  .thumbnail:hover {
    opacity: 0.8;
  }
  
  .list-unstyled li a {
    color:#fff;
    text-decoration: none;
    transition: color 0.3s;

  }
  
  .list-unstyled li a:hover {
    color: #ffcc00; /* Change this to your desired hover color */
  }
  
  .list-unstyled li a:hover .fa-chevron-right {
    color: #ffcc00; /* Change this to your desired arrow hover color */
  }

  /* Add to your custom CSS file or directly in a <style> tag */
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu > .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}

.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}


.navbar-container {
  width: 100%;
 
}

.navbar {
  margin-top: 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.navbar .dropdown-menu {
  animation-duration: 0.3s;
  animation-fill-mode: both;
}

.navbar li a.nav-link{ color:#fff !important;
} 
.navbar li.dropdown ul.dropdown-submenu li a.dropdown-item {  color:#000 !important;}

/* src/components/LatestNews.css */
.news-container {
  /* position: fixed;  */
  bottom: 20px; /* Distance from the bottom of the viewport */
  right: 20px;  /* Distance from the right of the viewport */
  width: 90%; /* Adjust the width as needed */
  height: 300px; /* Adjust the height as needed */
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1000; /* Ensures it's above other content */
}
.news-container.heading-section h2 { font-size: 1.5rem !important; }
.news-wrapper {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.news-content {
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden; /* Hide any overflow */
  animation: scrollUp 10s linear infinite;
}

.news-item {
  font-size: 1rem;
  color: #007bff;
  margin-bottom: 10px;
}

@keyframes scrollUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

/* src/components/CustomDropdown.css */

.custom-dropdown-toggle::after {
  content: "";
  display: inline-block;
  margin-left: 0.5em;
  vertical-align: 0.2em;
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid currentColor;
  border-right: 0.3em solid transparent;
  transition: transform 0.3s ease; /* Smooth transition for the arrow */
}

/* Rotate the arrow to the right on hover */
.nav-item.dropdown:hover .custom-dropdown-toggle::after,
.nav-item.dropdown.show .custom-dropdown-toggle::after {
  transform: rotate(-90deg);
  border-left: 0.3em solid transparent; /* Adjust borders for right-facing arrow */
  border-right: 0.3em solid currentColor;
}


/* Base styles for Navbar */
.navbar {
  background-color: #007bff; /* Blue primary background */
}

.navbar-brand, .nav-link {
  color: #ffffff; /* White text */
}

.navbar-toggler {
  border-color: #000000; /* Black border for the toggler */
}

.navbar-toggler-icon {
  background-image: url('data:image/svg+xml;charset=utf8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"%3E%3Cpath stroke="%23000000" stroke-width="2" d="M4 7h22M4 15h22M4 23h22"/%3E%3C/svg%3E'); /* Black icon for the toggler */
}

/* Custom CSS for responsive navbar and submenu handling */

/* Ensure navbar items are displayed correctly on mobile */
.navbar-collapse {
  display: flex;
  justify-content: flex-end;
}

/* Style the dropdown menu to prevent overflow */
.dropdown-menu {
  left: auto; /* Default to auto positioning */
  right: 0; /* Align to the right side */
  margin-left: 0;
  margin-right: 0; /* Ensure proper spacing */
}

/* Adjust submenu positioning to prevent overlap */
.dropdown-menu .dropdown-menu {
  left: 100%; /* Position to the right of the parent menu item */
  top: 0; /* Align at the top of the parent menu item */
  margin-top: 0; /* No extra margin */
  margin-left: 0; /* Ensure no overlap */
}

/* Ensure submenu is visible and does not overflow */
.dropdown-menu .dropdown-menu {
  display: block; /* Ensure dropdown is visible */
  position: absolute; /* Use absolute positioning */
}
.navbar-nav .dropdown-menu {
  width: auto; /* Automatically adjusts to the content width */
  min-width: 200px; /* Ensure it has a minimum width */
}

.navbar-nav .dropdown-submenu .dropdown-menu {
  left: 100%; /* Position the submenu to the right of its parent */
  top: 0;
  margin-left: 0.1rem; /* Small gap between parent and child menus */
}
.dropdown-item {
  white-space: normal; /* Allows text to wrap */
}
/* On smaller screens, ensure dropdowns are full-width */
@media (max-width: 768px) {
  .dropdown-menu {
    position: static; /* Reset position on small screens */
    margin: 0; /* Remove margins */
  }
  .dropdown-menu .dropdown-menu {
    position: static; /* Reset position on small screens */
    margin-left: 0; /* Ensure proper spacing */
  }
}

.affiliation-text {
  font-size:1 rem !important 
}
/* Optional: Hide HeaderTop on mobile devices and iPad Air */

@media (max-width: 820px) {
  .header-top-hidden {
    display: none;
  }
  .affiliation-text {
    font-size:.75rem !important 
  }
}

/* Optional: Ensure it is also hidden in landscape mode */
@media (max-width: 1180px) and (orientation: landscape) {
  .header-top-hidden {
    display: none;
  }
}

/* Optional: Center horizontal rule and add spacing */
.custom-hr {
  width: 50%;
  margin: 2rem auto; /* Centers the hr and adds vertical spacing */
  border-top: 1px solid #000; /* Adjust color and thickness as needed */
}

.navbar-brand {
  color: #f32f77 !important;
 
}