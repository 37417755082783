.carousel {
  position: relative;
  width: 100%;
  height: 55vh; /* Adjusted height to 60% of the viewport height */
  overflow: hidden;
}

.carousel-container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.carousel-slides {
  display: flex;
  transition: transform 0.5s ease-in-out;
  width: 100%;
  height: 100%;
}

.carousel-slide {
  flex: 0 0 100%;
  height: 100%;
  background-size: cover;
  background-position: center center; /* Adjusted to ensure the top of the image is not cut off */
  background-repeat: no-repeat;
  position: relative;
}

.carousel-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
}

.text-container {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 2;
  max-width: 90%;
  padding: 10px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
}

.typewriter {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  color: #fff;
  border-right: .15em solid orange;
  animation: blink-caret 0.75s step-end infinite;
}

@media (min-width: 1025px) {
  .typewriter.animate {
    animation: typing 4s steps(40, end) forwards;
  }
}

@media (max-width: 1024px) {
  .typewriter {
    animation: none;
  }
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: orange; }
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1000;
  font-size: 24px;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}

@media (max-width: 768px) {
  .carousel-button {
    font-size: 18px;
    padding: 8px;
  }

  .text-container {
    bottom: 10px;
    left: 10px;
    max-width: 80%;
    padding: 8px;
  }

  .typewriter {
    font-size: 1.5rem;
    white-space: normal;
  }
  .carousel{height: 200px }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .carousel-button {
    font-size: 20px;
    padding: 9px;
  }

  .text-container {
    bottom: 15px;
    left: 15px;
    max-width: 85%;
    padding: 10px;
  }

  .typewriter {
    font-size: 1.75rem;
    white-space: normal;
  }
}

@media (max-width: 480px) {
  .carousel-button {
    font-size: 16px;
    padding: 6px;
  }

  .text-container {
    bottom: 5px;
    left: 5px;
    max-width: 90%;
    padding: 6px;
  }

  .typewriter {
    font-size: 1.2rem;
  }
}
