/* General dropdown style */


.navbar-nav .dropdown-menu {
  display: none; /* Hide by default */
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

/* Show dropdown on hover */
.nav-item:hover > .dropdown-menu,
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}

/* Ensure submenu appears to the right of the parent */
.dropdown-submenu {
  position: relative;
}

.dropdown-submenu .dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -1px;
}
.navbar-collapse{flex-grow:0 !important}
.dropdown-submenu:hover > .dropdown-menu {
  display: block;
}
.navbar-nav .dropdown-menu {
  width: auto; /* Automatically adjusts to the content width */
  min-width: 240px; /* Ensure it has a minimum width */
}
/* Adjust dropdown-item to wrap text if necessary */
.dropdown-item {
  white-space: normal;

}


/* Accessibility: Add focus outline */
.navbar-nav .nav-link:focus, 
.navbar-nav .dropdown-item:focus {
  color: #000;
  outline: 1px solid #000;
}

/* Optional: Add transition for smooth dropdown appearance */
.dropdown-menu {
  transition: all 0.3s ease;
}

@media (max-width: 820px) {
  .navbar-collapse{justify-content:flex-start; display:flow}
  .navbar-brand{font-size:.75rem !important}
  .navbar-nav .dropdown-menu{position:static}
  
}