  /* styles.css or equivalent */
  .wrap-about {
    position: relative;
    height: 400px; /* Adjust based on your image size */
    overflow: hidden; /* Hide overflow if images are larger */
  }
  
  .imageComponent {
    position: absolute;
    opacity: 0; /* Initially hide the image */
    transition: opacity 1s ease-in-out;
  }
  
  .imageComponent.active {
    opacity: 1; /* Show the image when active */
  }
  .imageComponent img{ width:400 ;height:400; border:3px solid #ccc ; max-width: 100%;
    border-radius:1cap; 
    }
  
  
  .wrap-about img {
    width: 400;
    height: auto;
  }
  