/* Customize the main gallery container */
.image-gallery {
    background-color: #ffffff; /* Change background color */
    padding: 20px; /* Add padding */
    max-width: 98%; /* Ensure it doesn't stretch too wide */
    margin: 2rem auto; /* Center horizontally with auto margin */
    display: flex;
    justify-content: center; /* Center gallery within its container */
    align-items: center; /* Center gallery content vertically */
    flex-direction: column; /* Align the gallery items vertically */
    border:5px solid #ccc;
}

/* Customize the slideshow */
.image-gallery-slide {
    display: flex;
    justify-content: center; /* Center the slide image */
    align-items: center;
   
}

.image-gallery-thumbnails{ border:5px solid #ccc !important ; margin:5px 0}

/* Customize the thumbnails */
.image-gallery-thumbnails .image-gallery-thumbnail {
    border: 2px solid #045fc0; /* Add a border to thumbnails */
    opacity: 0.7; /* Reduce opacity */
    width: 150px; /* Set thumbnail width */
    height:auto;
    max-height: 150px !important; /* Set thumbnail height */
    display: inline-block; /* Ensure they are inline */
}

.image-gallery-thumbnails .image-gallery-thumbnail.active,
.image-gallery-thumbnails .image-gallery-thumbnail:hover {
    border-color: #158930; /* Change border color on active/hover */
    opacity: 1; /* Full opacity on active/hover */
    transform: scale(0.95); /* Slightly zoom out the thumbnail */
    transition: transform 0.3s ease; /* Smooth transition for the zoom effect */
}

/* Customize the caption (description) */
.image-gallery-slide .image-gallery-description {
    background-color: rgba(255, 255, 255, 0.1) !important; /* Darken the background */
    color: #494646; /* White text */
    padding: 10px; /* Add padding */
    font-size: 1.2em; /* Increase font size */
    text-align: center;
    width: 100%; /* Ensure the description spans the width of the gallery */
}
.image-gallery-content {
    position: relative;
    line-height: 0;
    top: 0;
    width: 100%;
}
.image-gallery-thumbnail .image-gallery-thumbnail-image {
    vertical-align: middle;
    width: 100%;
    line-height: 0;
    height: 100px;
}
.image-gallery-icon {
    color:  #28a745  !important;
    transition: all .3s ease-out;
    appearance: none;
    background-color: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    position: absolute;
    z-index: 4;
    filter: drop-shadow(0 1px 1px #8d8b8b);
}
@media (hover: hover) and (pointer: fine) {
    .image-gallery-icon:hover {
        color: #0700d4 !important;
    }
}

